import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import createLogger from 'vuex/dist/logger';
axios.defaults.withCredentials = true;

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state: {
    apiHost: '//' + location.hostname + (location.port ? ':' + 13577 : ''),
    sock: null,

    isValid: false,
    lang_tp: '',
    main_tp: '',
    envLightTP: 0,
    volume: 1,

    cateInfo: undefined,
    favInfo: undefined,
    langList: {},

    selectedCateA: 'all',
    selectedCateB: 'all',
    contsList: [],
    favList: {},

    keyword: ''
  },
  mutations: {
    updateConfig (state, payload) {
      state.isValid = true;
      state.lang_tp = payload.lang_tp || 'kr';
      state.main_tp = payload.main_tp || '*';
    },
    updateCateInfo (state, payload) {
      state.cateInfo = payload;
    },
    clearBasic (state) {
      Object.assign(state, {
        isValid: false,
        cateInfo: undefined,
        favInfo: undefined,
        langList: {},
      });
    },
    clearContsListAll (state) {
      Object.assign(state, {
        selectedCateA: 'all',
        selectedCateB: 'all',
        keyword: '',
        contsList: [],
      });
    },
    clearContsList (state) {
      state.contsList = [];
    },
    updateFavInfo (state, payload) {
      payload.sort((a, b) => a.fav_name < b.fav_name ? -1 : 1);

      const tree = [];
      const recusFunc = (_depth, _parentUid) => {
        payload.forEach((el) => {
          if (el.parent_uid == _parentUid) {
            tree.push(Object.assign(el, { depth: _depth }));
            recusFunc(_depth + 1, el.fav_uid);
          }
        });
      };
      recusFunc(0, 0);

      state.favInfo = tree;
    },
    updatelangList (state, payload) {
      state.langList = payload;
    },
    setSelectedCateA (state, payload) {
      state.selectedCateA = payload;
    },
    setSelectedCateB (state, payload) {
      state.selectedCateB = payload;
    },
    addContsList (state, payload) {
      if (payload.isFront) {
        state.contsList.unshift(...payload.list);
      } else {
        state.contsList.push(...payload.list);
      }
    },
    delContsList (state, payload) {
      state.contsList = state.contsList.filter(el => payload.indexOf(`${el.uu}-${el.uid}`) === -1);
    },
    updateContsList (state, payload) {
      const content = state.contsList.filter(el => el.uu == payload.uuid.uu && el.uid == payload.uuid.uid)[0];
      if (content) {
        Object.assign(content, payload.contsInfo);
      }
    },
    setKeyword (state, payload) {
      state.keyword = payload;
    },
    clearFavList (state, favUid) {
      if (favUid) {
        this.commit('addFidFavList', { fav_uid: favUid });
      } else {
        state.favList = {};
      }
    },
    addFidFavList (state, payload) {
      if (!state.favList[payload.fav_uid] || !payload.list) {
        delete state.favList[payload.fav_uid];
        Vue.set(state.favList, payload.fav_uid, {
          list: [],
          keyword: payload.keyword || '',
        });
      }

      const currFav = state.favList[payload.fav_uid];

      if (currFav.keyword != payload.keyword) {
        currFav.list = [];
        currFav.keyword = payload.keyword || '';
      }

      if (payload.isFront) {
        currFav.list.unshift(...(payload.list || []));
      } else {
        currFav.list.push(...(payload.list || []));
      }
    },
    delFidFavList (state, payload) {
      state.favList[payload.fid].list = state.favList[payload.fid].list.filter((el) => (
        el.uu != payload.uuid.uu || el.uid != payload.uuid.uid
      ));
    },
  },
  getters: {
    getMainTP (state) {
      return state.main_tp;
    },
    getNameSelectedCateA (state) {
      if (state.selectedCateA == 'search') {
        return '콘텐츠 검색';
      } else {
        return ((state.cateInfo || []).find(el => el.cate_uid == state.selectedCateA) || {}).cate_name || '';
      }
    },
    getNameSelectedCateB (state) {
      return ((state.cateInfo || []).find(el => el.cate_uid == state.selectedCateB) || {}).cate_name || '';
    },
    getListCateB (state) {
      return (state.cateInfo || []).filter(el => el.parent_uid == state.selectedCateA);
    },
    getCateOne: (state) => (id) => {
      return (state.cateInfo || []).find(el => el.cate_uid == id) || {};
    },
    getNameCateA: (state) => (id) => {
      return ((state.cateInfo || []).find(el => el.cate_uid == id) || {}).cate_name || '';
    },
    getFavInfo (state) {
      return state.favInfo || [];
    },
    getFavList (state) {
      return state.favList;
    },
    getLang (state) {
      return function (_key) {
        return state.langList[_key] ? state.langList[_key] : _key;
      };
    },
  },
  actions: {
    async loadBasic ({ commit, dispatch }, isForce) {
      if (isForce) commit('clearBasic');

      await dispatch('loadConfig');
      await Promise.all([dispatch('loadCateInfo'), dispatch('loadFavInfo'), dispatch('loadLang')]);
    },
    loadConfig ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.isValid) {
          resolve();
        } else {
          axios.post(`${state.apiHost}/api/data/config`).then((res) => {
            commit('updateConfig', res.data);
            resolve();
          }).catch((err) => {
            commit('clearBasic');
            reject(err);
          });
        }
      });
    },
    loadCateInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.cateInfo) {
          resolve();
        } else {
          axios.post(`${state.apiHost}/api/data/cate_info`).then((res) => {
            res.data.push({
              cate_uid: 'myCate',
              parent_uid: 0,
              cate_name: '다운로드',
              icon: 'cate_a/9.jpg',
            });

            commit('updateCateInfo', res.data);
            resolve();
          }).catch((err) => {
            commit('clearBasic');
            reject(err);
          });
        }
      });
    },
    loadFavInfo ({ commit, state }, isForce) {
      return new Promise((resolve, reject) => {
        if (state.favInfo && !isForce) {
          resolve();
        } else {
          axios.post(`${state.apiHost}/api/data/fav_info`).then((res) => {
            commit('updateFavInfo', res.data);
            resolve();
          }).catch(() => {
            commit('clearBasic');
            reject(err);
          });
        }
      });
    },
    loadLang ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (Object.keys(state.langList).length) {
          resolve();
        } else {
          axios.post(`${state.apiHost}/api/data/lang`).then((res) => {
            commit('updatelangList', res.data);
            resolve();
          }).catch(() => {
            commit('clearBasic');
            reject(err);
          });
        }
      });
    },
    loadContsInfo ({ state }, param) {
      return new Promise(resolve => {
        axios.post(`${state.apiHost}/api/contsInfo`, param).then((res) => {
          resolve(res.data);
        });
      });
    },
    loadContsList ({ state, commit }) {
      return new Promise(resolve => {
        const findCateA = state.selectedCateA == 'search' ? (state.main_tp == '*' ? 'all' : state.main_tp) : state.selectedCateA;
        axios.post(`${state.apiHost}/api/contsList`, {
          cateA: findCateA,
          cateB: state.selectedCateB,
          keyword: state.keyword,
          limit_st: state.contsList.length,
        }).then((res) => {
          commit('addContsList', { list: res.data });
          if (res.data) {
            resolve(res.data);
          } else {
            console.warn(res);
          }
        });
      });
    },
    async loadFavList ({ state, commit }, payload) {
      const currFav = state.favList[payload.fav_uid];

      const rs = await axios.post(`${state.apiHost}/api/favList`, {
        fid: payload.fav_uid,
        keyword: payload.keyword,
        limit_st: currFav ? currFav.list.length : 0,
      });

      commit('addFidFavList', {
        fav_uid: payload.fav_uid,
        list: rs.data,
        keyword: payload.keyword,
      });

      return rs.data;
    },
    async loadAddedFav ({ state }, param) {
      const rs = await axios.post(`${state.apiHost}/api/addedFav`, param);
      return rs.data;
    },
    // chgShowList: 즐겨찾기가 변경된 후 목록이 올바르게 보여지도록 list를 정리해준다.
    chgShowList ({ state, commit }, payload) {
      payload.arrConts.forEach((el) => {
        const [uu, uid] = el.split('-');
        const uuid = {
          uu: uu,
          uid: uid,
        };

        (async () => {
          let contsListOne;

          if (state.favList[payload.fid]) {
            commit('delFidFavList', {
              fid: payload.fid,
              uuid: uuid,
            });

            if (payload.isAdd) {
              contsListOne = contsListOne || (await axios.post(`${state.apiHost}/api/contsListOne`, uuid)).data;

              if (contsListOne && state.favList[payload.fid]) {
                commit('addFidFavList', {
                  isFront: true,
                  fid: payload.fid,
                  list: [contsListOne],
                });
              }
            }
          }

          const content = state.contsList.filter(el => el.uu == uu && el.uid == uid)[0];
          if (content) {
            contsListOne = contsListOne || (await axios.post(`${state.apiHost}/api/contsListOne`, uuid)).data;

            commit('updateContsList', {
              uuid: uuid,
              contsInfo: contsListOne,
            });
          }
        })();
      });
    },
  },
  modules: {
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});
