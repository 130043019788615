import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router/router';
import store from './store/store';
import Modal from './plugin/modal';
import jquery from 'jquery';
import MixinUtils from '@/mixin/utils';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false;
Vue.use(Modal);
Vue.mixin(MixinUtils);
Vue.use(VueCookies);

window.$ = window.jquery = jquery;

window.onImageError = function (image) {
  // 이미지 다운로드 실패시 한번 더
  const src = image.src;
  image.src = '/img/btn/bt_search.png';
  setTimeout(function () {
    image.onerror = onImageErrorFinal(image);
    image.src = `${src}${/\?/.test(src) ? '&' : '?'}${new Date().getTime()}`;
  }, 1500);
};

window.onImageErrorFinal = function (image) {
  // 이미지 다운로드 최종 실패시 대용 이미지
  setTimeout(function () {
    image.onerror = null;
    image.src = '/img/btn/bt_x.png';
  }, 100);
};

window.vueApp = new Vue({
  router,
  store,
  Vuex,
  render: h => h(App),
}).$mount('#app');
