import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import NotFound from '@/views/NotFound';

Vue.use(Meta, {
  keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'vmid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
});
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Main',
      component: () => import('@/views/Main.vue'),
    },
    {
      path: '/Signin',
      name: 'Signin',
      component: () => import('@/views/Signin.vue'),
      meta: { guest: true },
    },
    {
      path: '/Content',
      name: 'Content',
      component: () => import('@/views/ContentView.vue'),
    },
    {
      path: '/ContentsList',
      name: 'ContentsList',
      component: () => import('@/views/ContentsList.vue'),
    },
    {
      path: '/FavList',
      name: 'FavList',
      component: () => import('@/views/FavList.vue'),
    },
    {
      path: '/Setting',
      component: () => import('@/views/Setting.vue'),
    },
    {
      path: '/Translate',
      name: 'Translate',
      component: () => import('@/views/Translate.vue'),
    },
    {
      path: '/Download',
      name: 'Download',
      component: () => import('@/views/Download.vue'),
    },
    // {
    //   path: '/Upload3D',
    //   name: 'Upload3D',
    //   component: () => import('@/views/Upload3D.vue'),
    // },
    {
      path: '*',
      component: NotFound
    }
  ],
  scrollBehavior: function (to, from, savedPosition) {
    if (window.closeAlert) window.closeAlert();

    //console.log('[scrollBehavior]', '\nto', to, '\nfrom', from, '\nsavedPosition', savedPosition);
    if (savedPosition) {
      return new Promise(resolve => {
        setTimeout(function () {
          resolve(savedPosition);
        }, 10);
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (!to.meta.guest && !Vue.$cookies.get('token')) {
    return next({ name: 'Signin' });
  } else {
    return next();
  }
});

export default router;
