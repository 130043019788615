<template lang="html">
  <div class="NotFound__Wrapper">
    <span class="md-display-2">
      Ops... 404.
    </span>
  </div>
</template>

<script>
export default {
  mounted () {
    // location.replace('/');
  }
};
</script>

<style media="scss">
.NotFound__Wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
