<template>
  <!-- s:레이어팝업 -->
  <div
    v-show="is_show"
    id="layer_dvSelect"
    class="modal active"
    role="dialog"
    transition="modal"
  >
    <div class="modal_dialog">
      <div class="modal_content">
        <header class="modal_title">
          <h2>
            {{ title }}
          </h2>
          <a
            href="javascript:void(0)"
            class="btn_modal_close"
            @click="cancle"
          >
            <span class="ir">레이어팝업 닫기</span>
          </a>
        </header>
        <div class="modal_body">
          <div class="alert_txt" />
        </div>
        <footer class="modal_btns">
          <ul
            class="active"
            :class="{
              col2: button==2,
              col1: button==1
            }"
          >
            <li v-show="button == 2">
              <button
                class="btn_modal"
                @click="cancle"
              >
                {{ getLang('취소') }}
              </button>
            </li>
            <li>
              <button
                class="btn_modal"
                @click="confirm"
              >
                {{ getLang('확인') }}
              </button>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  </div>
  <!-- e:레이어팝업 -->
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/plugin/modal';

export default {
  data () {
    return {
      title: '',
      is_show: false,
      // adding callback function variable
      callback: null,
      button: 1,
    };
  },
  computed: {
    ...mapGetters({
      getLang: 'getLang'
    }),
  },
  beforeDestroy () {
    Modal.EventBus.$off('show');
  },
  mounted () {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Modal.EventBus.$on('show', this.show, false);
    window.closeAlert = this.close;
  },
  methods: {
    evEscape (ev) {
      if (ev.key == 'Escape') this.close();
    },
    show (params) {
      if (!params) return;

      this.title = params.title;
      this.callback = params.callback;
      this.button = params.button;
      this.is_show = true;

      const $dom = $('.modal_body .alert_txt');
      const txt = $dom.text(params.text).html();
      $dom.html(txt.replaceAll('\n', '<br>'));

      window.addEventListener('keyup', this.evEscape);
    },
    close () {
      this.is_show = false;
      window.removeEventListener('keyup', this.evEscape);
    },
    cancle () {
      if (this.button == 1) {
        this.confirm();
      } else {
        this.close();
      }
    },
    confirm () {
      // we must check if this.onConfirm is function
      if (typeof this.callback === 'function') {
        // run passed function and then close the modal
        this.callback();
        this.close();
      } else {
        // we only close the modal
        this.close();
      }
    }
  },
};
</script>
