export default {
  data () {
    return {
      langSelect: [
        ['cn2', 'Chinese (simplified)'],
        ['cn1', 'Chinese (traditional)'],
        ['en', 'English'],
        ['jp', 'Japanese'],
        ['kr', 'Korean'],
      ],
      langName: {
        cn1: 'zh-TW',
        cn2: 'zh-CN',
        en: 'en',
        jp: 'ja',
        kr: 'ko',
      },
    };
  },
  mounted () {
  },
  methods: {
    showAlert (title, text, callback = null, button = 1, isTranslate = true) {
      this.$modal.show({
        title: title,
        text: text,
        callback: callback,
        button: button,
        isTranslate: isTranslate,
      });
    },
    getBrowser () {
      const agnt = navigator.userAgent.toLowerCase();
      return (agnt.indexOf('naver') >= 0) ? 'naver' : (agnt.indexOf('kakao') >= 0) ? 'kakao' : (agnt.indexOf('samsung') >= 0) ? 'samsung' : (agnt.indexOf('webxrviewer') >= 0) ? 'webxrviewer' : (agnt.indexOf('chrome') >= 0) ? 'chrome' : '';
    },
    isIOS () {
      return ['webxrviewer'].indexOf(this.getBrowser) >= 0 || /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    },
    isMobile () {
      // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const isMobile = this.isIOS() || navigator.userAgent.match(/iPhone|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || navigator.userAgent.match(/LG|SAMSUNG|Samsung/) != null;
      return isMobile;
    },
    isKR () {
      return /^ko/.test(navigator.language || navigator.userLanguage);
    },
    checkConsonant (_str) {
      const charCode = _str.charCodeAt(_str.length - 1);
      return (charCode - 44032) % 28 ? '을' : '를';
    },
  },
};
